.constituent-tag-list {
    padding: 0px;
    margin: 0px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}

.show-more {
  cursor: pointer;
}

.show-more:hover {
  cursor: pointer;
  color: #c88242;
}
