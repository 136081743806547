body {
  margin: 0;
  padding: 0;
  font-family: sans-serif !important;
}

.ant-layout-header {
  background: #fff;
  padding: 0px 16px;
}
.doc-content-layout > .ant-breadcrumb,
.doc-content-layout > .ant-steps,
.doc-content-layout > .ant-layout-content {
  margin: 12px 16px 0;
}
.doc-content-layout > .ant-steps {
  margin-top: 50px;
  margin-bottom: 50px;
}
body,
.doc-content-layout > .ant-steps .ant-steps-head,
.doc-content-layout > .ant-steps .ant-steps-title {
  background-color: #ececec;
}

.doc-content-layout {
  min-height: 100vh;
}

.doc-content-wrapper {
  position: relative;
  float: left;
  width: 90%;
  padding-bottom: 200px;
  border-radius: 20px;
  height: fit-content;
  margin-left: 80px;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  padding: 24px;
  background: #fff;
}

@media (max-width: 768px) { 
  .doc-content-wrapper {
    margin-top: 30px;
    margin-left: 20px;
  }
 
 }

 @media (max-width: 1024px) { 
  .doc-content-wrapper {
    margin-top: 50px;
    margin-left: 50px;
  }
 
 }

@media (max-width: 480px) { 
 .doc-content-wrapper {
   margin-top: 0px;
   margin-left: 0px;
 }
}

.doc-page-dashboard .doc-content-wrapper {
    background: none;
}
.doc-page-reports .doc-content-wrapper {
    background: none;
}

