h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0.3em;
}

.logo {
  opacity: 0.67;
}

.modal-hide-ok .ant-modal-footer .ant-btn-primary {
  display: none;
}

.constituents-page-list .ant-list-item-meta {
  margin-bottom: 0px;
}

.tabSlideIn-wrap {
  overflow: hidden;
}

.tabSlideIn-left-appear, .tabSlideIn-right-appear {
    transition: margin-left .3s cubic-bezier(.645,.045,.355,1);
}
.tabSlideIn-left-appear {
    margin-left: 100%;
}
.tabSlideIn-right-appear {
    margin-left: -100%;
}
.tabSlideIn-left-appear-active, .tabSlideIn-right-appear-active {
    margin-left: 0;
}

a.ant-tabs-tab {
  color: inherit;
}
